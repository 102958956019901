<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          <span v-if="isCreate" class="">{{ $('create') }}</span>
          <span v-if="isUpdate" class="">{{ $('update') }}</span>
          <span v-if="isDelete" class="">{{ $('delete') }}</span>
          <span v-if="isBulkStudents" class="">{{ $('bulk_students') }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <div v-if="isBulkStudents">
        <v-container class="pb-0">
          <v-text-field
            v-model="search"
            clearable
            outlined
            hide-details
            label="ابحث عن طالب"
          />
          <v-virtual-scroll
            :items="filteredStudents"
            height="400"
            item-height="40"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.id">
                <v-list-item-title>
                  <v-checkbox v-model="selectedStudents[item.id].checked" :label="item.name">
                  </v-checkbox>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-container>
      </div>
      <v-card-text>
        <h2 v-if="isDelete && item" class="my-3">
          {{ $('delete_msg', [item.name]) }}
        </h2>
        <v-form
          v-if="!isDelete && !isBulkStudents"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.name"
                  v-max-length="200"
                  :label="$('name')"
                  :rules="rules.name"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>

              <v-col
                v-if="!isUpdate"
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="form.stage_id"
                  :items="stages"
                  :rules="rules.stage_id"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$('stage')"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isBulkStudents"
          color="info"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'groupDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    search: '',
    stages: [],
    students: [],
    selectedStudents: {},
    item: null,
    form: {
      name: '',
      stage_id: '',
    },
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    isBulkStudents() {
      return this.data.type === 'bulk_students'
    },
    filteredStudents() {
      return this.students.filter(s => s.name.includes(this.search ?? ''))
    },
    rules() {
      const rules = {};
      rules.name = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.name`)]),
      ];
      rules.stage_id = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.stage`)]),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            if (this.isBulkStudents) {
              const group = await axios.get(`/groups/${val.id}/students`);
              this.students = group.data.data;
              this.selectedStudents = {}
              this.students.forEach(student => {
                this.selectedStudents[student.id] = {
                  checked: parseInt(student.group_id, 10) === parseInt(val.id, 10),
                }
              })
            } else {
              const group = await axios.get(`/groups/${val.id}`);
              this.item = group.data.data;
              this.form.name = this.item.name;
              this.form.stage_id = this.item.stage_id;
            }
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  async mounted() {
    const response = await axios.get('/stages')
    this.stages = response.data.data
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDelete) {
          await axios.delete(`/groups/delete/${this.data.id}`);
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        console.log(this.isBulkStudents)
        if (this.isBulkStudents) {
          await axios.post(`/groups/${this.data.id}/students`, {
            ids: this.students.filter(s => this.selectedStudents[s.id].checked).map(s => s.id),
          });
          await toast.dispatch('success', this.$t('form.success.update'))
        } else {
          if (this.$refs.form.validate() === false && !this.isBulkStudents) {
            return;
          }
          if (this.isCreate) {
            await axios.post('/groups/create', this.form);
            await toast.dispatch('success', this.$t('form.success.create'))
          } else if (this.isUpdate) {
            await axios.post(`/groups/edit/${this.data.id}`, this.form);
            await toast.dispatch('success', this.$t('form.success.update'))
          }
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        name: '',
        stage_id: null,
      };
      this.item = null;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style>
.v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>
